import {makeDoc} from "@/components/UI/tiptap/src/proseMirrorFactory";
import {EditorState} from "prosemirror-state";
import {DOMSerializer} from "prosemirror-model";

export default {
    data() {
        return {
            text : '',
            json : {},

            view: null,
            state: null,
            schema : null,

            toolbarVisible : false,
            addItemsBarVisible : false,
            toolbarTop : 0,
            toolbarLeft : 0,
            addItemsTop : 0,
            addItemsLeft : 0,

            delayInputTimeout : null
        };
    },
    watch : {
        value(value) {
            if (!this.INPUT_WAS_UPDATED(value)) {
                return
            }
            this.UPDATE_EDITOR_STATE(value)
        }
    },
    methods : {
        // Editor functions
        DEFAULT_DISPATCH(transaction) {
            const newState = this.view.state.apply(transaction);
            this.view.updateState(newState);
            this.UPDATE_MAIN_TOOLBAR_POSITION();
            this.GET_EDITOR_CONTENT()
        },
        UPDATE_ADD_ITEMS_BUTTON_POSITION(selection) {
            const { from } = selection;
            const $from = this.view.state.doc.resolve(from);
            const isAtStartOfLine = $from.parentOffset === 0;
            const isLineEmpty = $from.parent.content.size === 0;

            if (isAtStartOfLine && isLineEmpty) {
                const coords = this.view.coordsAtPos(from);
                this.addItemsBarVisible = true;
                this.addItemsTop  = coords.top -8;
                this.addItemsLeft = coords.left - 40; // Position the button to the left of the line
            } else {
                this.addItemsBarVisible = false;
            }
        },
        UPDATE_MAIN_TOOLBAR_POSITION() {
            const selection = this.view.state.selection;
            if (selection.empty) {
                this.toolbarVisible = false;
                this.UPDATE_ADD_ITEMS_BUTTON_POSITION(selection);
                return;
            }

            const { from, to } = selection;
            const start = this.view.coordsAtPos(from);
            const end = this.view.coordsAtPos(to);

            // Calculate the initial toolbar position
            let toolbarTop = Math.min(start.top, end.top) - 50; // Position the toolbar above the selection
            let toolbarLeft = (start.left + end.left) / 2; // Center the toolbar horizontally

            // Get the dimensions of the toolbar
            const toolbarWidth = 400;
            const toolbarHeight = 40;

            // Get the viewport dimensions
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;

            // Adjust the position to prevent clipping
            if (toolbarLeft < 0) {
                toolbarLeft = 10; // Add some padding from the left edge
            } else if (toolbarLeft + toolbarWidth > viewportWidth) {
                toolbarLeft = viewportWidth - toolbarWidth - 10; // Add some padding from the right edge
            }

            if (toolbarTop < 0) {
                toolbarTop = 10; // Add some padding from the top edge
            } else if (toolbarTop + toolbarHeight > viewportHeight) {
                toolbarTop = viewportHeight - toolbarHeight - 10; // Add some padding from the bottom edge
            }

            // Update the toolbar position and visibility
            this.toolbarTop = toolbarTop;
            this.toolbarLeft = toolbarLeft;
            this.toolbarVisible = true;
        },

        // Emitters and Technical for values
        UPDATE_EDITOR_STATE(value) {
            if (this.view && value) {
                const doc = makeDoc(value,this.schema , this.toJson);
                const state = EditorState.create({
                    doc,
                    plugins: this.view.state.plugins,
                });
                this.view.updateState(state);
            }
        },
        INPUT_WAS_UPDATED(value) {
            if ( !this.toJson && value === this.text) {
                return false
            }
            if ( this.toJson && value === this.json) {
                return false
            }
            return true
        },
        GET_EDITOR_CONTENT() {
            if (this.toJson) {
                this.json = this.view.state.doc.toJSON()
                this.$emit('input' , this.json)
            } else {
                const fragment = DOMSerializer.fromSchema(this.schema).serializeFragment(this.view.state.doc.content);
                const div = document.createElement("div");
                div.appendChild(fragment);
                this.text = div.innerHTML;
            }
            this.$emit('input' , this.toJson ? this.json : this.text)
            this.EMIT_LATENCY_INPUT()
        },
        EMIT_CHANGE() {
            this.$emit('change' , this.toJson ? this.json : this.text)
        },
        EMIT_LATENCY_INPUT() {
            if ( this.toJson && this.json === this.value) {
                return
            }
            if ( !this.toJson && this.text === this.value) {
                return
            }
            if (this.delayInputTimeout) {
                clearTimeout(this.delayInputTimeout);
            }
            this.delayInputTimeout = setTimeout(() => {
                this.$emit('soft-input' , this.toJson ? this.json : this.text)
            },2000)
        },
    },
    beforeDestroy() {
        if (this.view) {
            this.view.destroy();
        }
    }
}