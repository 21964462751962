<template>
  <div style="position: relative" >
    <portal-target name="prose_mirror_portal"></portal-target>
    <!-- Toolbar-->
    <tipToolbar
        :visible="toolbarVisible"
        :top="toolbarTop"
        :left="toolbarLeft"
        :view="view"
        :schema="schema"
        :deselect-trigger="deselectTrigger"
        @dialog-active="toolbarDialogActive = $event"
        ref="toolbar"
    />
<!--    <v-btn v-if="addItemsBarVisible"-->
<!--           :style="`position : fixed; top : ${addItemsTop}px; left : ${addItemsLeft}px;`"-->
<!--           icon>-->
<!--      <v-icon :color="wsACCENT">mdi-cog</v-icon>-->
<!--    </v-btn>-->
    <!-- Editor Wrapper -->
    <div @focusout="EMIT_CHANGE"
         @change="EMIT_CHANGE"
         v-click-outside="deselect"
         class="proseView" ref="editor"
    ></div>
  </div>
</template>

<script>
import {makeDefaultSchema, makeDefaultState , makeDefaultView  } from "@/components/UI/tiptap/src/proseMirrorFactory";
import proseMirrorMixin from "@/components/UI/tiptap/src/proseMirrorMixin";
import tipToolbar from "./tipToolbar.vue";
import "@/components/UI/tiptap/src/proseMirrorStyles.scss";

export default {
  components: {
    tipToolbar
  },
  mixins : [proseMirrorMixin],
  props : {
    value : {},
    toJson : {
      type : Boolean,
      default : false
    },
    placeholder : {
      type : String,
      default : "EnterText"
    },
    lecture : {
      type : Boolean
    }
  },
  data() {
    return {
      mouseOnEditor : false,
      deselectTrigger : 0,
      toolbarDialogActive : false
    };
  },
  methods: {
    onMouseMove(event) {
      this.mouseOnEditor = !!this.isMouseInsideEditor(event.clientX, event.clientY);
    },
    isMouseInsideEditor(x, y) {
      const editor = this.$refs.editor;
      if (!editor) return false;
      const rect = editor.getBoundingClientRect();
      return (
          x >= rect.left &&
          x <= rect.right &&
          y >= rect.top &&
          y <= rect.bottom
      );
    },

    deselect() {
      if (this.mouseOnEditor || this.toolbarDialogActive) {
        return
      }
      this.deselectTrigger++
      this.EMIT_CHANGE()
      this.addItemsBarVisible = false;
      this.toolbarVisible = false;
    },
    // Editor functions
    dispatchTransaction(transaction) {
      this.DEFAULT_DISPATCH(transaction)
    },

    // Init Function
    initEditor() {
      this.schema = makeDefaultSchema()
      this.state = makeDefaultState({
        schema: this.schema,
        input: this.value,
        toJson : this.toJson,
        placeholder: this.$t(this.placeholder),
      })
      this.view = makeDefaultView(this.$refs.editor,this.state,this.dispatchTransaction)
    }

  },
  mounted() {
    this.initEditor()
    document.addEventListener('mousemove', this.onMouseMove);
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.onMouseMove);
  },
};
</script>

<style>
.proseView img {
  transition: opacity 0.1s;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}
.proseView img:hover {
  opacity: 0.8 !important;
}

</style>

